<template>
  <div class="loading" v-if="loading.length > 0">
    <v-progress-circular
      indeterminate
      color="#5b6cff"
      size="70"
      width="7"
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
<style lang="scss">
.loading {
  z-index: 1000;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
}
</style>